import {
    ref, onUnmounted, type Ref, watch,
} from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import { getScrollParent } from '../../../scripts/helpers/scroll';

type Options = {
    onHidden: () => void,
}

export function useVisibilityObserver(
    anchorRef: Ref<HTMLElement|undefined>,
    { onHidden }: Options,
) {
    const elementIsVisible = ref(false);

    const { stop: stopIntersectionObserver } = useIntersectionObserver(
        anchorRef,
        ([entry]) => {
            elementIsVisible.value = entry?.isIntersecting ?? false;
        },
        {
            root: () => (anchorRef.value ? getScrollParent(anchorRef.value) : undefined),
            threshold: [0, 1],
        },
    );

    watch(elementIsVisible, () => {
        if (elementIsVisible.value) {
            return;
        }
        onHidden();
    });

    onUnmounted(() => {
        stopIntersectionObserver();
    });

    return {
        elementIsVisible,
    };
}
