import * as t from 'io-ts';
import clamp from 'lodash-es/clamp';
import fromEnum from '../../../io-ts/fromEnum';

export const PointC = t.type({
    x: t.number,
    y: t.number,
});

export type Point = t.TypeOf<typeof PointC>;

export const PolygonC = t.type({
    points: t.array(PointC),
});

export type Polygon = t.TypeOf<typeof PolygonC>;

export enum MaskMode {
    INSENSITIVE = 'insensitive',
    SENSITIVE = 'sensitive',
}

export const MaskModeC = fromEnum('MaskMode', MaskMode);

const invertMap: Record<MaskMode, MaskMode> = {
    [MaskMode.SENSITIVE]: MaskMode.INSENSITIVE,
    [MaskMode.INSENSITIVE]: MaskMode.SENSITIVE,
};

export const invertMode = (mode: MaskMode): MaskMode => invertMap[mode];

export const MaskC = t.type({
    mode: MaskModeC,
    polygons: t.array(PolygonC),
});

export type Mask = t.TypeOf<typeof MaskC>;

const toAbsoluteValue = (value: number, area: number): number => (value / 100) * area;
const toRelativeValue = (value: number, area: number): number => (value / area) * 100;

const toAbsolutePoint = (point: Point, width: number, height: number): Point => {
    const x = clamp(toAbsoluteValue(point.x, width), 0, width);
    const y = clamp(toAbsoluteValue(point.y, height), 0, height);

    return { x, y };
};

const toRelativePoint = (point: Point, width: number, height: number): Point => {
    const x = clamp(toRelativeValue(point.x, width), 0, 100);
    const y = clamp(toRelativeValue(point.y, height), 0, 100);

    return { x, y };
};

export const toAbsoluteMask = ({ mode, polygons }: Mask, width: number, height: number): Mask => ({
    mode,
    polygons: polygons.map(
        (polygon) => ({
            points: polygon.points.map(
                (point) => toAbsolutePoint(point, width, height),
            ),
        }),
    ),
});

export const toRelativeMask = ({ mode, polygons }: Mask, width: number, height: number): Mask => ({
    mode,
    polygons: polygons.map(
        (polygon) => ({
            points: polygon.points.map(
                (point) => toRelativePoint(point, width, height),
            ),
        }),
    ),
});

/**
 * The Shoelace formula
 * @see https://www.101computing.net/the-shoelace-algorithm
 */
export const calculateArea = ({ points }: Polygon) => {
    let area = 0;
    const vertices = points.map(({ x, y }) => [x, y]);
    const n = vertices.length;

    for (let i = 0; i < n; i += 1) {
        const j = (i + 1) % n;
        area += points[i].x * points[j].y;
        area -= points[j].x * points[i].y;
    }

    return Math.abs(area) / 2;
};
