import Converter from '../Converter';
import {
    MotionDetectionMaskFieldPartial,
    RawMotionDetectionMaskField,
    RawMotionDetectionMaskFieldC,
} from '../types/MotionDetectionMask';
import isType from '../../../../io-ts/isType';
import { RawField } from '../types/RawField';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';

export default class MotionDetectionMaskConverter extends Converter<RawMotionDetectionMaskField> {
    public canConvert(field: RawField): field is RawMotionDetectionMaskField {
        return isType(field, RawMotionDetectionMaskFieldC);
    }

    protected convertPartially(
        field: RawMotionDetectionMaskField,
    ): MotionDetectionMaskFieldPartial {
        return {
            renderAs: FIELD_RENDER_TYPES.MOTION_DETECTION_MASK,
            value: field.value,
            cameraId: field.cameraId,
            disabledReason: field.disabledReason,
        };
    }
}
