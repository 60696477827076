import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import toMultipleChoice, {
    MultipleChoicePartialWithoutRendererC,
    RawMultipleChoiceWithoutRendererC,
} from './toMultipleChoice';
import {
    Choice, ParentChoice, ParentChoiceC, transformToParent,
} from '../types/Choice';

export const RawUsersGroupsChoiceC = t.intersection([
    RawMultipleChoiceWithoutRendererC,
    t.intersection([
        t.type({
            renderer: t.literal(FIELD_RENDER_TYPES.USERS_GROUPS_CHOICE),
        }),
        t.partial({
            attr: t.type({ search: t.boolean }),
        }),
    ]),
]);

export const RawUsersGroupsFilterC = t.intersection([
    RawMultipleChoiceWithoutRendererC,
    t.intersection([
        t.type({
            renderer: t.literal(FIELD_RENDER_TYPES.USERS_GROUPS_CHOICE),
        }),
        t.partial({
            attr: t.type({ search: t.boolean }),
        }),
    ]),
]);

export const UsersGroupsChoicePartialC = t.type({
    choices: t.array(ParentChoiceC),
    renderAs: t.literal(FIELD_RENDER_TYPES.USERS_GROUPS_CHOICE),
    search: t.boolean,
});

export const UsersGroupsFilterPartialC = t.intersection([
    MultipleChoicePartialWithoutRendererC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.USERS_GROUPS_CHOICE),
        search: t.boolean,
    }),
]);

export const UsersGroupsChoiceC = t.intersection([UsersGroupsChoicePartialC, FieldCommonPropsC]);

export type RawUsersGroupsChoice = TypeOf<typeof RawUsersGroupsChoiceC>;
export type RawUsersGroupsFilter = TypeOf<typeof RawUsersGroupsFilterC>;
export type UsersGroupsChoicePartial = TypeOf<typeof UsersGroupsChoicePartialC>;
export type UsersGroupsFilterPartial = TypeOf<typeof UsersGroupsFilterPartialC>;
export type UsersGroupsChoice = TypeOf<typeof UsersGroupsChoiceC>;

export const GROUP_PREFIX = 'group_';
export const USER_PREFIX = 'user_';

export const transformByParent = (choices: Choice[]): ParentChoice[] => {
    const result: ParentChoice[] = [];

    choices.forEach((choice: Choice) => {
        const value = choice.value as string;
        if (value.startsWith(GROUP_PREFIX)) {
            const parent = transformToParent(choice);
            result.push(parent);
            return;
        }

        const parent = result[result.length - 1];

        parent.children.push(choice);
    });

    return result;
};

export const toUsersGroupsFilter = (field: RawUsersGroupsFilter): UsersGroupsFilterPartial => {
    const multipleChoice = toMultipleChoice(field);
    const search = field.attr?.search ? field.attr.search : false;

    return ({
        renderAs: FIELD_RENDER_TYPES.USERS_GROUPS_CHOICE,
        choices: transformByParent(multipleChoice.choices),
        search,
    });
};

export default (field: RawUsersGroupsChoice): UsersGroupsChoicePartial => {
    const multipleChoice = toMultipleChoice(field);
    const search = field.attr?.search ? field.attr.search : false;

    return ({
        renderAs: FIELD_RENDER_TYPES.USERS_GROUPS_CHOICE,
        choices: transformByParent(multipleChoice.choices),
        search,
    });
};
