import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import { RawFieldC } from './RawField';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import { MaskC } from '../../../../types/device/motionDetection/Mask';

export const RawMotionDetectionMaskFieldC = t.intersection([
    RawFieldC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.MOTION_DETECTION_MASK),
        value: MaskC,
        cameraId: t.union([t.number, t.null]),
        disabledReason: t.union([t.string, t.null]),
    }),
]);

export const MotionDetectionMaskFieldPartialC = t.type({
    renderAs: t.literal(FIELD_RENDER_TYPES.MOTION_DETECTION_MASK),
    value: MaskC,
    cameraId: t.union([t.number, t.null]),
    disabledReason: t.union([t.null, t.string]),
});

export const MotionDetectionMaskFieldC = t.intersection(
    [MotionDetectionMaskFieldPartialC, FieldCommonPropsC],
);

export type RawMotionDetectionMaskField = TypeOf<typeof RawMotionDetectionMaskFieldC>;
export type MotionDetectionMaskFieldPartial = TypeOf<typeof MotionDetectionMaskFieldPartialC>;
export type MotionDetectionMaskField = TypeOf<typeof MotionDetectionMaskFieldC>;
