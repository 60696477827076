<template>
    <Teleport to="body">
        <Transition name="popover">
            <div
                v-if="show"
                ref="root"
                class="popover"
                data-cy="popover"
                :class="computedClass"
                :style="styles"
                v-bind="$attrs"
            >
                <slot />
            </div>
        </Transition>
    </Teleport>
</template>

<script lang="ts" setup>
import { computed, useTemplateRef, watch } from 'vue';
import { useClickOutsideListener } from '../../composables/clickOutsideListener';
import { Variant } from './Popover/Variant';

const root = useTemplateRef<HTMLDivElement>('root');

type Styles = {
    top: string;
    left: string;
    width: string | undefined;
};

type Props = {
    top: number,
    left: number,
    width?: number,
    variant?: Variant[keyof Variant] | null,
    customClass?: string;
    clickOutsideBypassIds?: string[]
};

const props = withDefaults(defineProps<Props>(), {
    customClass: undefined,
    variant: Variant.PRIMARY,
    width: undefined,
    clickOutsideBypassIds: () => [],
});

const show = defineModel<boolean>('show', { default: false });

type Emits = {
    (event: 'shown'): void;
    (event: 'hidden'): void;
};

const emit = defineEmits<Emits>();

const hidePopover = () => {
    if (show.value) {
        show.value = false;
    }
};

useClickOutsideListener([() => root.value, ...props.clickOutsideBypassIds], () => {
    hidePopover();
});

const styles = computed<Styles>(() => ({
    top: `${props.top}px`,
    left: `${props.left}px`,
    width: props.width ? `${props.width}px` : undefined,
}));

const computedClass = computed(() => [
    `popover--${props.variant}`,
    props.customClass,
]);

watch(show, async (value) => {
    if (!value) {
        emit('hidden');
        return;
    }

    emit('shown');
});

defineExpose({ root });
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/z-indexes';

.popover {
    position: absolute;
    z-index: $popover-z-index;

    max-width: 28.75rem;
    padding: 0;

    font-size: $font-size-base;

    border: 1px solid var(--theme-color-border-divider-primary);
    border-radius: $border-radius;
    box-shadow: var(--shadow-4);

    transition: opacity 0.2s ease;
}

.popover--primary {
    background-color: var(--theme-color-surface-primary-default);
}

.popover--secondary {
    color: var(--theme-color-text-inversed);

    background-color: var(--theme-color-surface-inversed-secondary);
}

.popover--mate-gray {
    color: var(--theme-color-text-always-white);

    background-color: var(--theme-color-surface-inversed-secondary);
    backdrop-filter: blur($blur);
}

.popover-enter-from,
.popover-leave-to {
    opacity: 0;
}
</style>
