import FIELD_RENDER_TYPES from '../helpers/fieldRenderTypes';
import SchemaConverter from '../helpers/schemaConverter/SchemaConverter';
import {
    Form,
    FormC,
    RawForm,
    RawFormC,
} from '../helpers/schemaConverter/converters/types/Form';

import {
    AudioField as AudioFieldType,
} from '../helpers/schemaConverter/converters/functions/toAudioFormat';
import { assertIsDefined } from '../typeAssertions/undefined';
import isType from '../io-ts/isType';
import assertIsTypeC from '../typeAssertions/isTypeC';
import { Field, FieldsRecord } from '../helpers/schemaConverter/converters/types/Field';
import { Choice, flat } from '../helpers/schemaConverter/converters/types/Choice';
import { FileFieldC } from '../helpers/schemaConverter/converters/functions/toFileUpload';
import { SAME_FILE_MARKER } from '../constants/file';
import { MotionDetectionMaskFieldC } from '../helpers/schemaConverter/converters/types/MotionDetectionMask';
import { Mask } from '../types/device/motionDetection/Mask';

const isSerializable = (field: Field)
    : boolean => field.renderAs !== FIELD_RENDER_TYPES.QUICK_BUTTON;

export function convertJsonForm(form: RawForm): Form | null {
    if (!isType(form, RawFormC)) {
        throw new Error('Invalid form provided');
    }

    const converter = new SchemaConverter(form);
    const convertedForm = converter.convert();

    assertIsTypeC(convertedForm, FormC);

    return convertedForm;
}

export const AUDIO_HIDDEN_PREFIX = 'audio_id_hidden_';

export const getAudioFieldKey = (fieldId: number | string): string => (
    `${AUDIO_HIDDEN_PREFIX}${fieldId}`
);

/**
 * @todo form serializer must replace this
 */
export function serializeFormFields(
    fields: FieldsRecord | undefined,
    formName: string,
    formDataObject: FormData = new FormData(),
    ignoreFieldTypes: FIELD_RENDER_TYPES[] | undefined = undefined,
): FormData {
    const data = formDataObject;

    if (fields === undefined) {
        return data;
    }

    Object.entries(fields).forEach(([fieldName, fieldData]) => {
        if (ignoreFieldTypes?.includes(fieldData.renderAs)) {
            return;
        }

        if (fieldData.renderAs === FIELD_RENDER_TYPES.CHECKBOX) {
            // for checkbox field if value is true then send value else nothing send
            if (fieldData.value) {
                data.append(`${formName}[${fieldName}]`, '1');
            }

            return;
        }

        if (!fieldData) {
            // empty field
            data.append(`${formName}[${fieldName}]`, '');

            return;
        }

        if (fieldData.renderAs === FIELD_RENDER_TYPES.AUDIO) {
            data.append(`${formName}[${fieldName}]`, `${fieldData.value}`);
            data.append(`${formName}[${getAudioFieldKey(fieldName)}]`, (fieldData as AudioFieldType).uid);

            return;
        }

        if (fieldName.startsWith(AUDIO_HIDDEN_PREFIX)) {
            // ignore

            return;
        }

        if (isType(fieldData, MotionDetectionMaskFieldC)) {
            const mask = fieldData.value as Mask;
            data.append(`${formName}[${fieldName}][mode]`, mask.mode);
            mask.polygons.forEach((polygon, rIndex) => {
                const polygonPrefix = `${formName}[${fieldName}][polygons][${rIndex}]`;
                polygon.points.forEach((point, pIndex) => {
                    const pointPrefix = `${polygonPrefix}[points][${pIndex}]`;
                    data.append(`${pointPrefix}[x]`, point.x.toString());
                    data.append(`${pointPrefix}[y]`, point.y.toString());
                });
            });

            return;
        }

        if (fieldData.value || fieldData.value === '') {
            // normal field
            data.append(`${formName}[${fieldName}]`, `${fieldData.value}`);

            return;
        }

        if (fieldData.selectedChoice) {
            // select
            if (fieldData.selectedChoice.value !== null) {
                data.append(`${formName}[${fieldName}]`, fieldData.selectedChoice.value.toString());
            }

            return;
        }

        if (fieldData.selectedChoices) {
            // multiselect
            fieldData.selectedChoices.forEach(({ value }) => {
                data.append(`${formName}[${fieldName}][]`, value ? value.toString() : '');
            });

            return;
        }

        if (fieldData.fields) {
            // form
            serializeFormFields(fieldData.fields, `${formName}[${fieldName}]`, data, ignoreFieldTypes);

            return;
        }

        if (fieldData.items) {
            // array
            fieldData.items
                .filter(isSerializable)
                .forEach((item, i) => {
                    if (item.fields) {
                        serializeFormFields(item.fields, `${formName}[${fieldName}][${i}]`, data);
                    } else if (
                        (item.value || item.value === '' || item.value === 0)
                        && item.title !== 'prototype'
                    ) {
                        data.append(`${formName}[${fieldName}][]`, `${item.value}`);
                    }
                });

            return;
        }

        if (fieldData.renderAs === FIELD_RENDER_TYPES.FILE) {
            if (fieldData.file && !fieldData.file.isFake) {
                data.append(`${formName}[${fieldName}]`, fieldData.file);
            } else if (fieldData.file?.isFake) {
                data.append(`${formName}[${fieldName}]`, SAME_FILE_MARKER);
            }

            return;
        }

        if (fieldData.renderAs === FIELD_RENDER_TYPES.MULTI_FILES) {
            assertIsDefined(fieldData.uploadFiles);
            assertIsDefined(fieldData.removedFiles);

            fieldData.uploadFiles.forEach((file: Blob) => {
                data.append(`${formName}[files][]`, file);
            });
            fieldData.removedFiles.forEach((fileId) => {
                data.append(`${formName}[removedFiles][]`, fileId.toString());
            });

            return;
        }

        if (fieldData.choices) {
            // choice tree
            const choices = fieldData.choices as Choice[];
            const flattedChoices = flat(choices);
            const selectedChoices = flattedChoices
                .filter((choice: Choice,
                    index: number,
                    filterChoices: Choice[]) => filterChoices
                    .indexOf(choice) === index && choice.selected && !choice.disabled);

            if (selectedChoices.length === 0) {
                return;
            }

            if (
                fieldData.renderAs === FIELD_RENDER_TYPES.RADIO_CHOICE
                || fieldData.renderAs === FIELD_RENDER_TYPES.RADIO_CHOICE_INLINE
            ) {
                const choice = selectedChoices[0];
                const value = choice.value?.toString() ?? '';
                data.append(`${formName}[${fieldName}]`, value);

                return;
            }

            selectedChoices.forEach((choice: Choice) => {
                const value = choice.value?.toString() ?? '';
                data.append(`${formName}[${fieldName}][]`, value);
            });

            return;
        }

        if (isType(fieldData, FileFieldC)) {
            if (fieldData.multiple) {
                fieldData.files.forEach((file: Blob) => {
                    data.append(`${formName}[${fieldName}][]`, file);
                });

                return;
            }

            if (fieldData.file) {
                data.append(`${formName}[${fieldName}]`, fieldData.file);
            }
        }
    });

    return data;
}

export function serializeModalFormFields(
    modalFieldsCollection: Record<string, Field>,
    formName: string,
    formDataObject: FormData,
): FormData {
    const fields: Record<string, Field> = {};

    const addToFields = (
        [sectionFieldName, sectionFieldData]: [string, Field],
    ): void => {
        fields[sectionFieldName] = sectionFieldData;
    };

    const addChildrenToFields = ([, modalFieldData]: [string, Field]) => {
        assertIsDefined(modalFieldData.fields);
        Object.entries(modalFieldData.fields).forEach(addToFields);
    };

    Object.entries(modalFieldsCollection).forEach(addChildrenToFields);

    return serializeFormFields(fields, formName, formDataObject);
}

/** @deprecated use function export instead */
export const jsonSchemaConverter = {
    methods: {
        /** @deprecated */
        convertJsonForm,
        /** @deprecated */
        serializeFormFields,
        /** @deprecated */
        serializeModalFormFields,
    },
};

export default jsonSchemaConverter;
