<template>
    <TargetingPopover
        v-model:show="show"
        :target="target"
        :title="name"
        :position="placement"
        :boundary-padding="boundaryPadding"
        :offset="offset"
        @shown="emit('shown')"
        @hidden="emit('hidden')"
    >
        <slot />
    </TargetingPopover>
</template>

<script lang="ts" setup>
import TargetingPopover from './TargetingPopover.vue';
import { Position } from '../../store/modules/globalSettings';

type Props = {
    cypressId: string | null,
    target: string,
    name: string|null,
    placement: Position,
    boundaryPadding: number,
    offset?: number,
};

withDefaults(defineProps<Props>(), {
    name: null,
    cypressId: null,
    placement: Position.BOTTOM_RIGHT,
    boundaryPadding: 50,
    offset: 0,
});

type Emits = {
    (event: 'shown'): void,
    (event: 'hidden'): void,
};
const emit = defineEmits<Emits>();

const show = defineModel('show', { default: false });
</script>
