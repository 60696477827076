enum Variant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    MATE_GRAY = 'mate-gray',
}

const variants: string[] = Object.values(Variant);
const validator = (value: string): boolean => variants.includes(value);

export { Variant, validator };
